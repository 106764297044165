import { useState, useEffect, useRef } from 'react'
import logo from './img/whats_profile.jpg';
import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import { FloatingWhatsApp } from 'react-floating-whatsapp';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Navbar, Container } from 'react-bootstrap';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import {
  Facebook, Instagram,  Linkedin, Tiktok,Envelope,
  Whatsapp
}
  from 'react-bootstrap-icons';
    
import contact from './img/contacto.png'
import logowhite from './img/logofooter.png'
import logogrupo from './img/grupofooter.png'
import Home from './pages/Home';
import Catalogo from './pages/Catalogo';



function App() {
  
  const [path, setPath] = useState(window.location.pathname)
  const handleClick = (eventKey) => {

    if( path == "/" || eventKey == "refContact" ) {
      eval(eventKey).current.scrollIntoView({
        behavior: 'smooth',
        block: 'start'
      })
    } else 
        window.location.href = "/"

  };


  return (
    <>
        <FloatingWhatsApp
          phoneNumber="5215580975718"
          accountName="Mandarina Promocionales"
          className="wa-style"
          statusMessage="En linea"
          avatar={ logo }
          chatMessage="Hola, ¡Bienvenido a Mandarina Promocionales! ¿Cómo podemos ayudarte?"
          allowClickAway
          notification={false}
        />
     
      <BrowserRouter>
        <Routes>
          <Route path='/' element={<Home />} />
          <Route path='home' element={<Home />} />
          <Route path='catalogo' element={<Catalogo/>}/>
        </Routes>
      </BrowserRouter>
     
      </>
  );
}

export default App;
