import React, { useState, useEffect } from 'react'
import Card from 'react-bootstrap/Card'
import Button from 'react-bootstrap/Button'
import Modal from 'react-bootstrap/Modal'
import Carousel from 'react-bootstrap/Carousel'

import noImage from "./img/noimage.jpg"

const ProductCard = ({ product }) => {
  const [showModal, setShowModal] = useState(false);

  useEffect(() => {
    checkImage()
  }, []);

  const handleShowModal = () => {
    setShowModal(true);
    };


  const handleCloseModal = () => {
    setShowModal(false);
  };

  const firstImage = 
    product.variants.variant && Array.isArray(product.variants.variant)
      ? product.variants.variant[0].image500px
      : product.variants.variant.image500px;
   
  const checkImage = () => {
    //console.log("product.variants", product.variants.variant)
  }

  const truncatedInfo =
    product.extendedinfo.length > 100
      ? product.extendedinfo.substring(0, 100) + '...'
      : product.extendedinfo;

  const checkSource = ( url ) => {
    try{
      const src = require(url)
      return src; 
    }
    catch(err){
      return noImage
    }
  }

  return (
    <>
      <Card>
        {/*<Card.Img
          variant="top"
          src={firstImage}
          style={{ height: '205px', objectFit: 'contain', padding: '15px' }}
        />*/}
        <Card.Body>
          { ( !product.variants.variant || !Array.isArray(product.variants.variant) ) &&
            <div align="center">
              <img
                src={ product.variants.variant.image500px }
                onError={ (e) => { e.target.onError = null; e.target.src = noImage }}
                style={{ height: '205px', objectFit: 'contain', padding: '15px' }}
              />
            </div>
          }
            
          { ( product.variants.variant && Array.isArray(product.variants.variant) ) && 
            <Carousel>
              { product.variants.variant.map( item => (
                <Carousel.Item align="center">
                  <img
                    src={ item.image500px }
                    onError={ (e) => { e.target.onError = null; e.target.src = noImage }}
                    style={{ height: '205px', objectFit: 'contain', padding: '15px' }}
                  />
                </Carousel.Item>
              )) }
            </Carousel>
          }          
          <Card.Title>
            <div style={{ fontSize: '12px', color: 'gray' }}>{ 'Ref: ' + product.ref}</div>
            <div>{product.name}</div>
          </Card.Title>
          <Card.Text>{product.extendedinfo}</Card.Text>
          <br/>
          {/*<Button className='btn-more' onClick={handleShowModal}>
            Ver más
          </Button>*/}
        </Card.Body>
      </Card>

      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>{product.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{product.extendedinfo}</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Cerrar
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ProductCard;
